import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="relative">
        <div className="absolute z-1 w-full space-y-8">
          <h1 className="text-blue-500 text-8xl font-extralight">
            <a href="http://github.com/Kevin-Aaaquil" target="_blank" rel="noopener noreferrer">Eshan Singh</a>
          </h1>
          <p className="text-babypurple text-3xl">
            Busy building, please have patience.
          </p>
        </div>
        <video
          autoPlay
          muted
          loop
          className="object-cover w-full h-screen m-auto z-0 relative opacity-70"
        >
          <source
            src="https://eshansingh.s3.ap-south-1.amazonaws.com/Dr-Strange.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}

export default App;
